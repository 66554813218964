import { AssessmentStatus } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useListAssessments } from '@sparx/assessments/src/api/assessment';
import { AssessmentCard } from 'components/assessmentselector/AssessmentCard';
import { useMemo } from 'react';
import { isInDate, isOnlineAssessment } from 'utils/assessments';

interface AssessmentSelectorProps {
  selectedName?: string;
  onSelect?: (assessmentName: string) => void;
}

export const AssessmentSelector = ({ selectedName, onSelect }: AssessmentSelectorProps) => {
  const { data } = useListAssessments(
    { subjectName: 'subjects/-', includeAssessmentGroupAssessments: false },
    { suspense: true },
  );

  // Filter and sort the assessments
  const assessments = useMemo(
    () =>
      (data?.assessments || [])
        .filter(
          a =>
            !a.hidden &&
            a.status !== AssessmentStatus.WILL_NOT_DO &&
            a.status !== AssessmentStatus.COMING_SOON,
        )
        .filter(a => isOnlineAssessment(a) && isInDate(a))
        .sort(
          (a, b) =>
            a.subjectKey.localeCompare(b.subjectKey) ||
            (a.ukYearGroup || 100) - (b.ukYearGroup || 100) ||
            a.displayName.localeCompare(b.displayName),
        ),
    [data?.assessments],
  );

  return (
    <>
      <p style={{ marginBottom: 'var(--spx-unit-4)' }}>Select an assessment:</p>
      <div
        style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 'var(--spx-unit-4)' }}
      >
        {assessments.map(a => (
          <AssessmentCard
            assessment={a}
            key={a.name}
            selected={a.name === selectedName}
            onSelect={onSelect ? () => onSelect(a.name) : undefined}
          />
        ))}
      </div>
    </>
  );
};
