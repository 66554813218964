import {
  ListAssessmentSittingParticipantsResponse,
  ListAssessmentSittingParticipantsResponse_SittingParticipant,
  ListSittingsResponse_SittingData,
  Student as AssessmentsStudent,
  Student as SittingStudent,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp as pbTimestamp } from '@sparx/api/google/protobuf/timestamp';
import { Date as pbDate } from '@sparx/api/google/type/date';
import { format } from 'date-fns';
import { distance } from 'fastest-levenshtein';
import { useMemo } from 'react';

export type SittingLookup = Record<string, ListSittingsResponse_SittingData>;

export interface Link {
  studentId: string;
  fuzzy?: boolean;
}

export interface ParticipantMatch {
  participant: ListAssessmentSittingParticipantsResponse_SittingParticipant;
  resolvedStudentId?: string;
  enteredDetails?: AssessmentsStudent;
  matchedDetails?: Student;
  matchMethod?: 'login' | 'manual';
}

export interface GuestParticipantMatch extends ParticipantMatch {
  enteredDetails: AssessmentsStudent;
}

export interface ResolveParticipantMatch extends ParticipantMatch {
  matchedDetails: Student;
}

const participantIsSparxStudent = (
  participant: ListAssessmentSittingParticipantsResponse_SittingParticipant,
) =>
  participant.participantSubject.startsWith('student/') ||
  participant.participantSubject.startsWith('students/');

export const useParticipantMatch = (
  data: ListAssessmentSittingParticipantsResponse | undefined,
  studentLookup: Record<string, Student>,
) =>
  useMemo(() => {
    const participants: ParticipantMatch[] = [];
    for (const participant of data?.participants || []) {
      const sparxStudent = participantIsSparxStudent(participant)
        ? studentLookup[participant.participantSubject.split('/')[1]]
        : undefined;
      const studentDetails = data?.students.find(d => d.subject === participant.participantSubject);
      const resolvedStudentId =
        sparxStudent?.studentId || studentDetails?.linkedStudentName?.split('/')[1];
      const manualMatchStudent = studentLookup[resolvedStudentId || ''];

      participants.push({
        participant,
        resolvedStudentId,
        enteredDetails: studentDetails,
        matchedDetails: sparxStudent || manualMatchStudent,
        matchMethod: sparxStudent ? 'login' : manualMatchStudent ? 'manual' : undefined,
      });
    }
    return participants;
  }, [data?.participants, data?.students, studentLookup]);

export const calculateSuggestedLinks = (
  participants: GuestParticipantMatch[],
  students: Student[],
) => {
  const links: Record<string, Link> = {};
  for (const p of participants) {
    if (p.resolvedStudentId) continue; // already linked
    if (participantIsSparxStudent(p.participant)) continue; // student

    // Find a a link and use it if none is set already
    const student = findExactMatchStudent(p.enteredDetails, students);
    if (student) {
      links[p.participant.participantSubject] = { studentId: student.studentId };
    } else {
      const possible = findPossibleMatchStudent(p.enteredDetails, students);
      if (possible) {
        links[p.participant.participantSubject] = {
          studentId: possible.studentId,
          fuzzy: true,
        };
      }
    }
  }
  return links;
};

const findExactMatchStudent = (participant: SittingStudent, students: Student[]) => {
  const participantDOB = formatDateOfBirth(participant.dateOfBirth);
  for (const student of students) {
    const studentDOB = formatDateOfBirth(student.dateOfBirth);

    const [pGiven, pFamily, sGiven, sFamily] = [
      participant.givenName.toLowerCase(),
      participant.familyName.toLowerCase(),
      student.givenName.toLowerCase(),
      student.familyName.toLowerCase(),
    ];

    if (
      ((pGiven === sGiven && pFamily === sFamily) || (pGiven === sFamily && pFamily === sGiven)) && // handle accidental flip
      participantDOB === studentDOB
    ) {
      return student;
    }
  }
  return undefined;
};

const findPossibleMatchStudent = (participant: SittingStudent, students: Student[]) => {
  let minDist = Infinity;
  let bestMatch: Student | undefined = undefined;
  for (const student of students) {
    const dist = distance(
      `${participant.givenName} ${participant.familyName}`,
      `${student.givenName} ${student.familyName}`,
    );
    if (dist < minDist) {
      minDist = dist;
      bestMatch = student;
    }
  }
  if (
    bestMatch &&
    minDist < 6 &&
    // Dont' suggest on really short names
    participant.givenName.length + participant.familyName.length > 3
  ) {
    return bestMatch;
  }

  // Try matching on date of birth instead
  const participantDOB = formatDateOfBirth(participant.dateOfBirth);
  for (const student of students) {
    const studentDOB = formatDateOfBirth(student.dateOfBirth);
    if (participantDOB === studentDOB) {
      return student;
    }
  }

  return undefined;
};

export const formatDateOfBirth = (dob: pbDate | pbTimestamp | undefined) =>
  dob && 'year' in dob
    ? format(pbDate.toJsDate(dob), 'yyyy-MM-dd')
    : dob && 'seconds' in dob
      ? format(pbTimestamp.toDate(dob), 'yyyy-MM-dd')
      : '';
