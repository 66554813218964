import { ThemeProvider } from '@sparx/design/context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SuspenseRoute } from 'app/SuspenseRoute';
import { RouterProvider } from 'react-router-dom';

import { QueryProvider } from './QueryProvider';
import { router } from './router';
import sparxDesignStyles from './SparxDesign.module.css';

export const App = () => (
  <ThemeProvider themeStyles={sparxDesignStyles}>
    <SuspenseRoute>
      <QueryProvider>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryProvider>
    </SuspenseRoute>
  </ThemeProvider>
);
