import { Product } from '@sparx/api/apis/sparx/types/product';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { getSchool } from '@sparx/query/schools-service';
import { useGroups } from 'api/groups';
import { useStudents } from 'api/students';
import { useMemo } from 'react';

export const useIsMisReady = (groupType: StudentGroupType | undefined) => {
  const { name: productName, product } = groupTypeToProduct(groupType);

  const { data: groups, isLoading: isLoadingGroups } = useGroups();
  const { data: students, isLoading: isLoadingStudents } = useStudents();
  const { data: school, isLoading: isLoadingSchool } = getSchool.useQuery();

  const { studentsInGroups, groupsOfType } = useMemo(() => {
    const groupsOfType =
      groups?.filter(g => g.type === groupType).map(g => g.name.split('/')[3]) || [];
    const studentsInGroups =
      students?.filter(s => s.studentGroupIds.find(gid => groupsOfType.includes(gid))) || [];
    return {
      studentsInGroups: studentsInGroups.length,
      groupsOfType: groupsOfType.length,
    };
  }, [groupType, groups, students]);

  // Only show the message if the school has the product
  const schoolHasProduct = useMemo(
    () => school?.products.find(p => p === product),
    [product, school],
  );

  if (isLoadingGroups || isLoadingStudents || isLoadingSchool || !schoolHasProduct) {
    return undefined;
  }

  if (studentsInGroups === 0 || groupsOfType === 0) {
    return `No students have been imported into ${productName} classes yet. Please use ${productName} to import students from your MIS.`;
  }
  return undefined;
};

const groupTypeToProduct = (groupType: StudentGroupType | undefined) => {
  switch (groupType) {
    case StudentGroupType.CLASS:
      return { name: 'Sparx Maths', product: Product.SPARX_MATHS };
    case StudentGroupType.CLASS_ENGLISH:
      return { name: 'Sparx Reader', product: Product.SPARX_READER };
    case StudentGroupType.CLASS_SCIENCE:
      return { name: 'Sparx Science', product: Product.SPARX_SCIENCE };
    default:
      return { name: 'Sparx' };
  }
};
