import { Product } from '@sparx/api/apis/sparx/types/product';
import { SparxQueryProvider as SpxQuery } from '@sparx/query';
import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import { loadSchoolId } from 'api/auth';
import { PropsWithChildren } from 'react';

import { queryClient } from './queryClient';

export const QueryProvider = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    <SparxQueryProvider>{children}</SparxQueryProvider>
  </QueryClientProvider>
);

const SparxQueryProvider = ({ children }: PropsWithChildren) => {
  const { data: schoolName } = useQuery({
    queryKey: ['school', 'name'],
    queryFn: async () => {
      const schoolID = loadSchoolId();
      return `schools/${schoolID}`;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: true,
  });

  return (
    <SpxQuery schoolName={schoolName || ''} product={Product.SPARX_ASSESSMENTS}>
      {children}
    </SpxQuery>
  );
};
