import { ListSittingsResponse } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment, AssessmentStatus } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useAssessments } from '@sparx/assessments/src/api/hooks';
import { Tabs } from '@sparx/sparx-design/components/tabs/Tabs';
import { useEndedSittings, useSittings } from 'api/sittings';
import { useSubjects } from 'api/subjects';
import { AssessmentTags } from 'components/assessmenttags/AssessmentTags';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { isInDate } from 'utils/assessments';

import styles from './AssessmentsView.module.css';

const countSittingsByAssessment = (sittings: ListSittingsResponse) => {
  const counts = new Map<string, number>();
  for (const sitting of sittings.sittings) {
    const count = counts.get(sitting.sitting!.assessmentName) || 0;
    counts.set(sitting.sitting!.assessmentName, count + 1);
  }
  return counts;
};

export const AssessmentsView = () => {
  const [params, setSearchParams] = useSearchParams();
  const { data: subjects = [] } = useSubjects({ suspense: true });
  const subject = params.get('subject') || subjects[0].key;

  const { data: activeSittings } = useSittings({
    suspense: true,
    select: countSittingsByAssessment,
  });
  const { data: endedSittings } = useEndedSittings({
    suspense: true,
    select: countSittingsByAssessment,
  });

  const { data } = useAssessments(
    {
      subjectName: 'subjects/-',
      includeAssessmentGroupAssessments: false,
    },
    {
      suspense: true,
      select: data =>
        data.assessments.filter(
          a =>
            a.subjectKey === subject &&
            // TODO: remove this - this is hiding the Primary SRA 2024
            a.name !== `assessments/fa7d2420-6b78-11ef-834a-d3a68f240dc5` &&
            (a.status === AssessmentStatus.READY || a.status === AssessmentStatus.COMING_SOON) &&
            isInDate(a),
        ),
    },
  );

  const sortByDisplayName = (a: string, b: string) =>
    a.localeCompare(b, undefined, { numeric: true });

  const sortedAssessments = useMemo(
    () => [...(data || [])].sort((a, b) => sortByDisplayName(a.displayName, b.displayName)),
    [data],
  );

  return (
    <PageContainer>
      <PageHeader>Assessments</PageHeader>
      <Tabs
        value={subject}
        onValueChange={sub =>
          setSearchParams(p => {
            p.set('subject', sub);
            return p;
          })
        }
      >
        <Tabs.List>
          {subjects.map(s => (
            <Tabs.Trigger key={s.key} value={s.key}>
              {s.name}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs>

      {sortedAssessments.map(a => (
        <AssessmentCard
          assessment={a}
          activeCount={activeSittings?.get(a.name)}
          endedCount={endedSittings?.get(a.name)}
          key={a.name}
        />
      ))}
    </PageContainer>
  );
};

const AssessmentCard = ({
  assessment,
  activeCount,
  endedCount,
}: {
  assessment: Assessment;
  activeCount?: number;
  endedCount?: number;
}) => {
  const tags = <AssessmentTags assessment={assessment} />;
  if (assessment.status === AssessmentStatus.COMING_SOON) {
    return (
      <div className={styles.AssessmentsCard}>
        <span className={styles.AssessmentsCardName}>{assessment.displayName}</span>
        <span className={styles.AssessmentsCardComingSoon}>Coming soon</span>
      </div>
    );
  }

  return (
    <Link to={`/teacher/${assessment.name}`} className={styles.AssessmentsCard}>
      <span className={styles.AssessmentsCardName}>{assessment.displayName}</span>
      {activeCount && <span className={styles.ActiveCount}>{activeCount} active sittings</span>}
      {endedCount && <span className={styles.EndedCount}>{endedCount} ended sittings</span>}
      {tags}
    </Link>
  );
};
