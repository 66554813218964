import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
  AnalyticsContext,
  UserInteractionEventDefaults,
  UserInteractionEventFields,
  UserInteractionEventFieldsWithLabelsRequired,
} from './analyticsContext';
import { useGAPump, useInteractionGatewayPump } from './pumps';

export type analyticsProviderConfig = {
  application: string;
  schoolID: string;
  userID: string;
  sessionID: string;
  version: string;
  pumps: pumpConfigs;
  debugLogs: boolean;
};

export type pumpConfigs = {
  interactionGateway?: {
    URL: string;
  };
  ga?: {
    gaPropertyID: string;
  };
};

export const LibAnalyticsProvider = ({
  children,
  config,
}: PropsWithChildren<{ config: analyticsProviderConfig }>) => {
  const connectionId = useMemo(() => uuid(), []);

  const defaultFields: UserInteractionEventDefaults = useMemo(
    () => ({
      application: config.application,
      schoolId: config.schoolID,
      userId: config.userID,
      version: config.version,
      sessionId: config.sessionID,
      connectionId: connectionId,
    }),
    [config, connectionId],
  );

  // pumps are only enabled when the relevant config is provided
  const sendIGEvent = useInteractionGatewayPump(
    config.application,
    !!config.pumps.interactionGateway,
    config.pumps.interactionGateway?.URL,
  );
  const sendGAEvent = useGAPump(
    config.application,
    !!config.pumps.ga,
    config.pumps.ga?.gaPropertyID,
  );

  const pumps = useRef([sendIGEvent, sendGAEvent]);
  pumps.current[0] = sendIGEvent;
  pumps.current[1] = sendGAEvent;

  const eventIndex = useRef(0);

  const sendEvent = useCallback(
    (event: UserInteractionEventFields) => {
      if (config.debugLogs) {
        console.info('Analytics: registering event: ', event);
      }
      const eventWithLabels = convertLabelsToStrings(event);

      pumps.current.forEach(sendEvent => {
        sendEvent({
          ...defaultFields,
          timestamp: Timestamp.now(),
          eventIndex: eventIndex.current,
          page: location.pathname,
          ...eventWithLabels,
          labels: { ...event.labels, hostname: window.location.hostname },
        });
      });
      eventIndex.current++;
    },
    [config.debugLogs, defaultFields],
  );

  return <AnalyticsContext.Provider value={sendEvent}>{children}</AnalyticsContext.Provider>;
};

/**
 * convertLabelsToStrings converts the labels on an event to strings, and adds an empty labels object to the event if
 * there are no labels present.
 */
const convertLabelsToStrings = (event: UserInteractionEventFields) => {
  if (hasLabels(event)) {
    Object.keys(event.labels).forEach(label => {
      event.labels[label] = event.labels[label].toString();
    });
  } else {
    event.labels = {};
  }
  return event as UserInteractionEventFieldsWithLabelsRequired;
};

/** hasLabels narrows the type of a given event if it has labels */
const hasLabels = (
  event: UserInteractionEventFields,
): event is UserInteractionEventFieldsWithLabelsRequired => {
  return event.labels !== undefined;
};
